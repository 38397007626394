import React from 'react'
import './footer.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation('footer');
    const currentYear = new Date().getFullYear();

    const metaNavItems = [
        { title: t("Impressum"), target: '__blank', link: "/de/impressum/" },
        { title: t("Datenschutz"), target: '__blank', link: "https://www.swiss-cycling.ch/de/datenschutz/" },
    ];


    return (
        <footer>
            <div className="footer">
                <span>
                    &#169; {currentYear} Swiss Cycling
                </span>

                <ul className="nav footer-nav">
                    {metaNavItems.map((item, index) => {
                        return (
                            <li key={index} className="item">
                                <Link to={item.link} target={item.target}>{item.title}</Link>
                            </li>
                        )
                    }
                    )}
                </ul>

            </div>
        </footer>
    )
}
